import React from "react";
import CommonProduct from "../components/commonPage"

class AboutPage extends CommonProduct {
    constructor(props) {
        super(props)
        this.firstRender = true
    }

    render() {
        let BaseComponent = this.BaseComponentRender.bind(this)
        return (
            <BaseComponent className="page" menu="about" title="About | Edtech Index">
            <h1>About</h1>
            <p className="strong">
            Since 2012, the Edtech Index has been the first stop for educators in search of learning technology solutions. The redesigned Edtech Index features enhanced product profiles and validations from trusted education and technology organizations. Now, educators will be able to find the most complete, reliable and up-to-date information available on learning technology products.
            </p>
            <p>
                We believe that access to information is key to making equitable decisions that improve teaching and learning. Powered by ISTE's <a href="https://www.iste.org/ltd" target="_blank">Learning Technology Directory</a>, the Edtech Index provides educators better visibility into high-quality solutions.
            </p>
            <p>
                In service of our community of students, educators, district leaders, higher education stakeholders, state decision makers and solution providers, ISTE is proud to host and support the Edtech Index as a free resource for the field. Gathering and dispersing this information is truly a collaborative effort, and we couldn't do this without the support of our funders and partners. Thank you to the following organizations who have partnered with us in our collective vision to improve effective edtech discovery and decision making: 
            </p>
            <br/>
            <div className="clear dividingLine"/>
            <br/>
            <br/>
            <div className="partners">
                <div className="partner_div"><a href="https://ccsso.org/" target="_blank"><img src="../images/1.CCSSO_color_logo-TM.jpg" alt="Council of Chief State School Officers"/></a>           </div>
                <div className="partner_div"><a href="https://www.innovateedunyc.org/" target="_blank"><img src="../images/2.InnovateEDU logo transparent background.PNG" alt="InnovateEDU"/></a>     </div>
                <div className="partner_div"><a href="https://www.commonsensemedia.org/" target="_blank"><img src="../images/3.H-LOGO-Common_Sense-RGB.svg" alt="Common Sense"/></a>                  </div>
                <div className="partner_div"><a href="https://digitalpromise.org/" target="_blank"><img src="../images/6.DP_Logo_2020_Clr_print.jpg" alt="Digital Promise"/></a>                      </div>
                <div className="partner_div"><a href="https://educationmarkets.org/" target="_blank"><img src="../images/7.edsolutions.jpg" alt="CEMD"/></a>                                          </div>
                <div className="partner_div"><a href="https://www.dell.org/" target="_blank"><img src="../images/4.MSDF_logo.jpg" alt="Michael and Susan Dell Foundation"/></a>                       </div>
                <div className="partner_div"><a href="https://www.gatesfoundation.org/" target="_blank"><img src="../images/9.gates_foundation.png" alt="Bill and Melinda Gates Foundation"/></a>     </div>
                <div className="partner_div"><a href="https://theatlis.org/" target="_blank"><img src="../images/ATLISLogo2023.png" alt="Association of Technology Leaders In Independent Schools"/></a></div>
            </div>

            <div className="clear"/>
            <div className="disclaimer">*Our partners do not endorse any specific product listed in the Index.</div>
            <br/>
            <div className="clear"/>
            </BaseComponent>
        )
    }
}
export default AboutPage